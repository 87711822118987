





















import "tippy.js/themes/light.css";

import * as workerTimers from "worker-timers";

import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import { HardBreak, Mention, Placeholder } from "tiptap-extensions";
import tippy, { sticky } from "tippy.js";

import Fuse from "fuse.js";
import { dispatchListManagers } from "@/store/main/actions";
import { readManagers } from "@/store/main/getters";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new HardBreak(),
          new Placeholder({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            emptyNodeText: "Type a message...",
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new Mention({
            // a list of all suggested items
            items: () => this.managers,
            // is called when a suggestion starts
            onEnter: ({ items, query, range, command, virtualNode }) => {
              this.query = query;
              this.filteredUsers = items;
              this.suggestionRange = range;
              this.renderPopup(virtualNode);
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command;
            },
            // is called when a suggestion has changed
            onChange: ({ items, query, range, virtualNode }) => {
              this.query = query;
              this.filteredUsers = items;
              this.suggestionRange = range;
              this.navigatedUserIndex = 0;
              this.renderPopup(virtualNode);
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null;
              this.filteredUsers = [];
              this.suggestionRange = null;
              this.navigatedUserIndex = 0;
              this.destroyPopup();
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              if (event.key === "ArrowUp") {
                this.upHandler();
                return true;
              }
              if (event.key === "ArrowDown") {
                this.downHandler();
                return true;
              }
              if (event.key === "Enter") {
                this.enterHandler();
                return true;
              }
              return false;
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            // onFilter: async (items, query) => {
            //   if (!query) {
            //     return items;
            //   }
            //   await new Promise((resolve) => {
            //     workerTimers.setTimeout(resolve, 250);
            //   });
            //   const fuse = new Fuse(items, {
            //     threshold: 0.2,
            //     keys: ["name"],
            //   });
            //   return fuse.search(query).map((item) => item.item);
            // },
          }),
        ],
        onUpdate: ({ state, getHTML, getJSON, transaction }) => {
          const value = {
            HTML: getHTML(),
            JSON: getJSON(),
          };
          this.$emit("comment-input-change", value);
        },
      }),
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
    };
  },
  computed: {
    hasResults() {
      return this.filteredUsers.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    },
    managers() {
      const orgs = readManagers(this.$store);
      return orgs
        .filter((item) => item.active)
        .map((item) => {
          return {
            id: item.id,
            name: [item.first_name, item.last_name].join(" "),
          };
        });
    },
  },
  methods: {
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler() {
      this.navigatedUserIndex =
        (this.navigatedUserIndex + this.filteredUsers.length - 1) %
        this.filteredUsers.length;
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler() {
      this.navigatedUserIndex =
        (this.navigatedUserIndex + 1) % this.filteredUsers.length;
    },
    enterHandler() {
      const user = this.filteredUsers[this.navigatedUserIndex];
      if (user) {
        this.selectUser(user);
      }
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectUser(user) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.name,
        },
      });
      this.editor.focus();
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderPopup(node) {
      if (this.popup) {
        return;
      }
      // ref: https://atomiks.github.io/tippyjs/v6/all-props/
      this.popup = tippy(".mention-text-box", {
        getReferenceClientRect: node.getBoundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        plugins: [sticky],
        content: this.$refs.suggestions,
        trigger: "manual",
        showOnCreate: true,
        theme: "light",
        placement: "bottom-start",
        inertia: true,
        duration: [400, 200],
      });
    },
    destroyPopup() {
      if (this.popup) {
        this.popup[0].destroy();
        this.popup = null;
      }
    },
  },
  beforeDestroy() {
    this.destroyPopup();
  },
  async mounted() {
    await dispatchListManagers(this.$store);
  },
};
