



















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { readIsManager, readIsSuper, readMe } from "@/store/auth/getters";

import MentionTextBox from "@/components/MentionTextBox.vue";

@Component({
  components: {
    MentionTextBox,
  },
})
export default class CommentsLog extends Vue {
  public selectedCategory = "Comment";
  public categoryOptions = ["Comment", "Contact"];
  public commentInput = { HTML: "", JSON: "" };
  public editedFullComment: any = null;
  public editing = false;
  public cancelEditingVisible = false;
  @Prop(Array) public comments: any[] | undefined;

  get user() {
    return readMe(this.$store);
  }

  get isSuper() {
    return readIsSuper(this.$store);
  }

  get readonly() {
    return !readIsManager(this.$store);
  }

  public commentInputChange(payload) {
    this.commentInput = payload;
  }

  public submitComment() {
    if (this.editing) {
      const payload = {
        HTML: this.commentInput.HTML,
        JSON: this.commentInput.JSON,
        author_id: this.user.id,
      };
      this.$emit("submit-update-comment", {
        id: this.editedFullComment.id,
        data: payload,
      });
    } else {
      const payload = {
        HTML: this.commentInput.HTML,
        JSON: this.commentInput.JSON,
        category: this.selectedCategory.toString().toLowerCase(),
        author_id: this.user.id,
      };
      this.$emit("submit-new-comment", payload);
    }
  }

  public async editComment(commentId: number) {
    const commentInputEl = this.$refs.commentInput as HTMLElement;
    if (commentInputEl) {
      commentInputEl.scrollIntoView({
        behavior: "smooth",
      });
    }

    let comment;
    const filtered = this.comments.filter((item) => item.id === commentId);
    if (filtered.length > 0) {
      comment = filtered[0];
    }
    this.editing = true;
    this.$refs["mention-text-box"]["editor"]["setContent"](comment.HTML);
    this.editedFullComment = comment;
    this.commentInput = comment;
  }

  public cancelEditing() {
    this.$refs["mention-text-box"]["editor"]["clearContent"]();
    this.editing = false;
    this.cancelEditingVisible = false;
  }
}
